import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Crepe Corner",
  pageDescription:
    "Find out how a Belgian-inspired breakfast cafe uses SpotOn's restaurant POS system to make decisions that drive more sales and higher revenue.",
  title: "Selling more crepes with smarter data",
  subTitle: `How Crepe Corner uses reporting from SpotOn to drive more sales.`,
  businessName: "Crepe Corner",
  industry: "Restaurants & hospitality, casual dining",
  location: "Providence and Cranston, Rhode Island",
  products: [spotonProducts.RESTAURANT, spotonProducts.ORDER],
  description:
    "Bringing the flavors of Belgium to Rhode Island, Chef Natacha Legein opened two Providence area location of her popular breakfast cafe—Crepe Corner. With SpotOn integrated into her restaurants, she can maintain a bird's-eye view of her operations and make decisions that drive more sales and higher profits.",
  quoteData: {
    imgName: "crepe-corner.png",
    quote: `“With SpotOn, I can give my accountant access to my reports, which is awesome for monthly bookkeeping. It makes their life easy to have everything in one system, versus them missing information and us having to calculate. It’s a huge time saver and makes a big difference for our end-of-year taxes.”`,
    personName: "Natacha Legein",
    personTitle: "Owner, Crepe Corner",
  },
  goal: `To center the menu around her guests and better organize her staff, Legein needed to know how each item was selling and how servers were performing. She also wanted to bring in more revenue by giving guests a way to order online.`,
  solution: `With the help of SpotOn's product mix and employee reports, Legein was able to remove unpopular items and adjust staff schedules during slower times. And with SpotOn Order, she could offer online ordering without commission-based fees.`,
  // solutionReadMore: {
  //   text: `Read more about their story.`,
  //   url: `https://spoton.com/blog/chiva-loka-spoton-capital-funds/`,
  // },
  results: {
    title: `The results`,
    stats: [
      { title: `8 – 15%`, description: `increase in monthly sales` },
      {
        title: `8%`,
        description: `decrease in labor`,
      },
      { title: `12.6%`, description: `more sales through online ordering` },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Data insights made over easy",
    imageName: "omelet-shoppe.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-03",
    href: "/case-studies/omelet-shoppe",
    goals: [
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.STREAMLINE_OPERATIONS,
    ],
  },
  {
    title: "Attracting more fast-casual vegan customers",
    imageName: "majani-soulful-vegan-cuisine.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-07",
    href: "/case-studies/majani-soulful-vegan-cuisine",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.CONNECT_AND_SELL_ONLINE,
    ],
  },
  {
    title: "Selling tacos, turning tables",
    imageName: "la-chiva-loka.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-14",
    href: "/case-studies/la-chiva-loka",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
];
